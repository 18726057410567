var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"w128"},[_c('div',{staticClass:"content"},[_c('img',{staticClass:"imgs",attrs:{"src":require("../../assets/fanhu.png"),"alt":""},on:{"click":_vm.goback}}),_c('div',{staticClass:"left"},[_c('div',{staticClass:"top"},[_c('div',{class:_vm.$route.path == '/Layoutstudy/studyLecture'
                ? 'item active'
                : 'item',on:{"click":function($event){return _vm.go('/Layoutstudy/studyLecture')}}},[_vm._v(" 听课 ")]),_c('div',{class:_vm.$route.path == '/Layoutstudy/know' ||
              _vm.$route.path == '/Layoutstudy/knowdetail'
                ? 'item active'
                : 'item',on:{"click":function($event){return _vm.go('/Layoutstudy/know')}}},[_vm._v(" 学知 ")]),_c('div',{class:_vm.$route.path == '/Layoutstudy/studyVideo'
                ? 'item active'
                : 'item',on:{"click":function($event){return _vm.go('/Layoutstudy/studyVideo')}}},[_vm._v(" 视频 ")]),_c('div',{class:_vm.$route.path == '/Layoutstudy/writing' ? 'item active' : 'item',on:{"click":function($event){return _vm.go('/Layoutstudy/writing')}}},[_vm._v(" 练答 ")]),_c('div',{class:_vm.$route.path == '/Layoutstudy/examination'
                ? 'item active'
                : 'item',on:{"click":function($event){return _vm.go('/Layoutstudy/examination')}}},[_vm._v(" 考试 ")])]),_c('div',{staticClass:"content1"},[_c('router-view')],1)]),_c('div',{staticClass:"rigth"},[_c('div',{staticClass:"title"},[_vm._v("课程信息")]),_c('div',{staticClass:"introduce"},[_vm._v(" "+_vm._s(_vm.detail.title)+" ")]),_c('div',{staticClass:"hour"},[_c('div',[_vm._v(_vm._s(_vm.detail.stu || 0)+"人已学")]),_c('div',[_vm._v("学习时长："+_vm._s(_vm.detail.ks || 0)+"课时")])]),_c('div',{staticClass:"title"},[_vm._v("课程介绍")]),_c('div',{staticClass:"introduce"},[(_vm.detail.kcjs)?_c('div',{staticClass:"txt",domProps:{"innerHTML":_vm._s(_vm.detail.kcjs)}}):_c('img',{staticClass:"img",attrs:{"src":require("../../assets/zanwuxinxi_icon.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("暂无信息")])]),_c('div',{staticStyle:{"height":"24px"}}),_c('div',{staticClass:"title"},[_vm._v("老师信息")]),_c('div',{staticClass:"box4"},[_c('div',{staticClass:"box4_4"},[_c('img',{attrs:{"src":require("../../assets/morentouxiang_icon.png"),"alt":""}}),_c('div',[_c('div',{staticStyle:{"font-size":"16px","font-family":"PingFangSC-Regular, PingFang SC","font-weight":"400","color":"#343434"}},[_vm._v(" 老师名称： ")]),_c('div',{staticStyle:{"font-size":"16px","font-family":"PingFangSC-Regular, PingFang SC","font-weight":"400","color":"#137cfb"}},[_vm._v(" "+_vm._s(_vm.detail.username || "暂无信息")+" ")])])]),_vm._m(0),_c('div',{staticClass:"btn",on:{"click":_vm.answer}},[_vm._v("课程答疑")]),_c('div',{staticClass:"btn",on:{"click":_vm.questionnaire}},[_vm._v("调查问卷")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box4_41"},[_c('div',[_c('div',[_c('img',{attrs:{"src":require("../../assets/shouji_icon.png"),"alt":""}}),_vm._v("老师手机 ")]),_c('div',{staticStyle:{"margin-top":"16px","font-size":"16px","font-family":"PingFangSC-Regular, PingFang SC","font-weight":"400","color":"#137cfb"}},[_vm._v(" 暂无信息 ")])]),_c('div',[_c('div',[_c('img',{attrs:{"src":require("../../assets/weixin_icon.png"),"alt":""}}),_vm._v("老师微信 ")]),_c('div',{staticStyle:{"margin-top":"16px","font-size":"16px","font-family":"PingFangSC-Regular, PingFang SC","font-weight":"400","color":"#137cfb"}},[_vm._v(" 暂无信息 ")])])])}]

export { render, staticRenderFns }