<template>
  <div class="box">
    <div class="w128">
      <div class="content">
        <img class="imgs" @click="goback" src="../../assets/fanhu.png" alt="" />
        <div class="left">
          <div class="top">
            <div
              @click="go('/Layoutstudy/studyLecture')"
              :class="
                $route.path == '/Layoutstudy/studyLecture'
                  ? 'item active'
                  : 'item'
              "
            >
              听课
            </div>
            <div
              @click="go('/Layoutstudy/know')"
              :class="
                $route.path == '/Layoutstudy/know' ||
                $route.path == '/Layoutstudy/knowdetail'
                  ? 'item active'
                  : 'item'
              "
            >
              学知
            </div>
            <div
              @click="go('/Layoutstudy/studyVideo')"
              :class="
                $route.path == '/Layoutstudy/studyVideo'
                  ? 'item active'
                  : 'item'
              "
            >
              视频
            </div>
            <div
              @click="go('/Layoutstudy/writing')"
              :class="
                $route.path == '/Layoutstudy/writing' ? 'item active' : 'item'
              "
            >
              练答
            </div>
            <div
              @click="go('/Layoutstudy/examination')"
              :class="
                $route.path == '/Layoutstudy/examination'
                  ? 'item active'
                  : 'item'
              "
            >
              考试
            </div>
          </div>
          <div class="content1"><router-view></router-view></div>
        </div>
        <div class="rigth">
          <div class="title">课程信息</div>
          <div class="introduce">
            {{ detail.title }}
          </div>
          <div class="hour">
            <div>{{ detail.stu || 0 }}人已学</div>
            <div>学习时长：{{ detail.ks || 0 }}课时</div>
          </div>
          <div class="title">课程介绍</div>
          <div class="introduce">
            <div class="txt" v-html="detail.kcjs" v-if="detail.kcjs"></div>
            <img
              class="img"
              v-else
              src="../../assets/zanwuxinxi_icon.png"
              alt=""
            />
            <div class="text">暂无信息</div>
          </div>
          <div style="height: 24px"></div>
          <div class="title">老师信息</div>
          <div class="box4">
            <div class="box4_4">
              <img src="../../assets/morentouxiang_icon.png" alt="" />
              <div>
                <div
                  style="
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #343434;
                  "
                >
                  老师名称：
                </div>
                <div
                  style="
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #137cfb;
                  "
                >
                  {{ detail.username || "暂无信息" }}
                </div>
              </div>
            </div>
            <div class="box4_41">
              <div>
                <div>
                  <img src="../../assets/shouji_icon.png" alt="" />老师手机
                </div>
                <div
                  style="
                    margin-top: 16px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #137cfb;
                  "
                >
                  暂无信息
                </div>
              </div>
              <div>
                <div>
                  <img src="../../assets/weixin_icon.png" alt="" />老师微信
                </div>
                <div
                  style="
                    margin-top: 16px;

                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #137cfb;
                  "
                >
                  暂无信息
                </div>
              </div>
            </div>
            <div class="btn" @click="answer">课程答疑</div>
            <div class="btn" @click="questionnaire">调查问卷</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      classid: (state) => state.classid,
    }),
  },
  data() {
    return {
      detail: {},
    };
  },
  methods: {
    ...mapActions(["getSignIn", "getClassDet"]),
    goback() {
      this.$router.push("/Layout/Learningcenter");
    },
    //问题答疑
    answer() {
      this.$router.push("/Layoutstudy/answer");
    },
    questionnaire() {
      this.$router.push("/Layoutstudy/survey");
    },
    go(val, num) {
      this.$router.push(val);
    },
  },
  mounted() {
    let that = this;

    //打卡签到
    this.getSignIn({
      class: this.classid,
      username: this.userInfo.username,
    });
    this.getClassDet({
      classid: this.classid,
      username: this.userInfo.username,
    }).then((res) => {
      if (res.code == 200) {
        this.detail = res.data.list;
        // window["_NLBps"].trackEvent("learn_page", {
        //   // 学员平台登录账号
        //   userId: that.userInfo.id.toString(),
        //   // 学员名称
        //   userName: that.userInfo.name.toString(),
        //   // 学员电话
        //   phoneNumber: that.userInfo.phone.toString(),
        //   // 学员所学课程
        //   courseName: that.details.title.toString(),
        //   // 学员所学在班级编号
        //   classNo: that.classid.toString(),
        // });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.box {
  background-color: #f7f7f7;
  overflow: hidden;
}
.w128 {
  width: 1280px;
  margin: 0 auto;
}
.imgs {
  cursor: pointer;
  position: absolute;
  top: 21px;
  left: -65px;
}
::v-deep .txt {
  img {
    width: 100% !important;
  }
}
.content {
  position: relative;
  margin-bottom: 21px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  .left {
    width: 992px;
    .top {
      display: flex;
      justify-content: space-around;
      height: 80px;
      line-height: 80px;
      background: #ffffff;
      .item {
        cursor: pointer;
        position: relative;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a7a7a7;
      }
      .active {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        &::before {
          position: absolute;
          display: block;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          content: "";
          width: 96px;
          height: 4px;
          background: #137cfb;
        }
      }
    }
    .content1 {
      margin-top: 12px;
      padding: 47px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .rigth {
    padding: 22px 12px;
    box-sizing: border-box;
    width: 268px;
    background-color: #fff;
    .title {
      padding-left: 10px;
      box-sizing: border-box;
      height: 56px;
      line-height: 56px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      border-bottom: 1px solid #e1e1e1;
    }
    .introduce {
      width: 100%;
      margin-top: 20px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      .img {
        display: block;
        margin: 0 auto;
      }
      .text {
        margin-top: 15px;
        text-align: center;
      }
    }
    .hour {
      margin-bottom: 23px;
      margin-top: 23px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7c7c7c;
    }
    .box4 {
      margin-top: 23px;
      .box4_4 {
        display: flex;
        img {
          margin-right: 10px;
          width: 50px;
          height: 50px;
        }
      }
      .box4_41 {
        margin-top: 33px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6c6c6c;
        display: flex;
        justify-content: space-around;
        img {
          margin-right: 8px;
          vertical-align: middle;
        }
      }
      .btn {
        cursor: pointer;
        margin-top: 27px;
        margin-bottom: 30px;
        text-align: center;
        line-height: 39px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fff;
        height: 39px;

        background: #137cfb;
      }
    }
  }
}
</style>
